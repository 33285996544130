export const HTTP_STATUS = {
  OK: 200,
  CONTENT_WAS_DELETED: 204,
  REDIRECT: 302,
  BAD_REQUEST: 400,
  NOT_FOUND: 404,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_ALLOWED: 405,
  CONFLICT: 409,
  UNPROCESSABLE_ENTITY: 422,
  FAILED_DEPENDENCY: 424,
  INTERNAL_ERROR: 500,
  NOT_IMPLEMENTED: 501,
  EXCEEDED_LIMIT: 429,
  BLOCKED_USER: 410,
  UNVERIFIED: 412,
};

export const API = {
  SAVE_FILES: '/files',
  PROPERTIES_BASE: '/properties',
  BECOME_A_SUPLIER: '/verifications/invitation',
  USER_REG: '/users',
  USER_LOGIN: '/sessions',
  TOKEN_VERIFY: '/verifications/tokens',
  PASSWORD_RESET: '/verifications/password',
  EMAIL_VERIFY: '/verifications/email',
  NEW_EMAIL_VERIFY: '/verifications/new-email',
  CURRENCIES: '/currencies',
  SUPPLIERS: '/suppliers',
  SUPPLIERS_PASSWORD: '/suppliers/password',
  PROPERTIES: '/properties',
  TERMS_CONDITIONS: '/terms-and-conditions',
  TRANSPORTS: '/transports',
  SURROUNDINGS: '/surroundings',
  KEY_FACILITIES: '/key-facilities',
  FACILITIES: 'facilities',
  SEARCH_ROOMS: '/search/rooms',
  CUSTOMER_PROFILE_SETTINGS: '/users/my/profile',
  CUSTOMER_SEARCH_PROFILE_SETTINGS: '/users/my/search-profile',
  NATIONALITY: '/nationality',
  CUSTOMERS_PASSWORD: '/users/my/password',
  CUSTOMERS_EMAIL: '/users/my/email',
  BOOKINGS_LIST: '/bookings',
  SUPPLIERS_STRIPE_CREATE: '/suppliers/stripe/create',
  SUPPLIERS_STRIPE_GET_ACCOUNT: '/suppliers/stripe',
  SEARCH_OFFERS_SUITABLE: '/search/offers/suitable',
  ERRORS: {
    INVALID_TOKEN: 'Your token is invalid',
  },
  PARTNERS: '/partners'
};

export const LOCALSTORAGE_KEYS = {
  SESSION: 'session',
  USER: 'user',
  PROFILING: 'profiling',
  REGUSER: 'regUser',
  RESET_PWD_EMAIL: 'resetPwdEmail',
  SEARCH: 'searchFormData',
  ACTIVITY_SEARCH: 'activitySearchFormData',
  ITINERARIES_SEARCH: 'itinerariesSearchFormData',
  COOKIES: 'hideCookies',
  HOME_TAB: 'selectedHomepageTab',
  COOKIE_VALUES: 'cookies',
  ACTIVITY_IDS: 'activityIds',
  SEARCH_DATES: 'searchDates',
};

export const TABS = {
  ITINERARY: 0,
  ACTIVITY: 1,
  STAY: 2,
};

export const COOKIES_KEYS = {
  PARTNER_NICKNAME: 'BP',
};

export const COOKIES_LIFETIME = 1; // day

export const SESSIONSTORAGE_KEY = {
  PARTNER_LOGO: 'partnerLogo',
  LOGO_SUBTITLE: 'logoSubtitle',
  USER_GUIDE: 'userGuide',
  DESTINATION_LIST: 'destinationList',
};

export const LOCALSTORAGE_PRICE_KEYS = {
  MIN_PRICE: 'minPrice',
  MAX_PRICE: 'maxPrice',
  ITINERARIES_MIN_PRICE: 'itinerariesMinPrice',
  ITINERARIES_MAX_PRICE: 'itinerariesMaxPrice',
  ACTIVITIES_MIN_PRICE: 'activitiesMinPrice',
  ACTIVITIES_MAX_PRICE: 'activitiesMaxPrice',
  STAYS_MIN_PRICE: 'staysMinPrice',
  STAYS_MAX_PRICE: 'staysMaxPrice',
};

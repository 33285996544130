const ROLES = {
  SUPPLIER: 'SUPPLIER',
  RETAIL_CUSTOMER: 'RETAIL_CUSTOMER',
  USER: 'USER',
  PARTNER: 'PARTNER'
};

export default ROLES;

export const REDIRECT_ROUTES = {
  SUPPLIER: '/sellers',
  RETAIL_CUSTOMER: '/customers',
  USER: '/signin',
};

export const nonSellerRoles = [ROLES.USER, ROLES.RETAIL_CUSTOMER, ROLES.PARTNER];

import { LOCATION_ERRORS } from '../constans/error-messages';

export const findAndReturnChangedError = (error) => {
  console.log('error', error);
  for (const item of LOCATION_ERRORS) {
    if (item.SERVER_ERROR === error) {
      return item.READABLE_ERROR;
    }
  }

  return error;
};

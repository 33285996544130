import axios from 'axios';
import toastr from 'toastr';
import { navigate } from 'gatsby';

import StorageService from './storage.service';
import { HTTP_STATUS } from '../constans/http';
import { LOCALSTORAGE_KEYS } from '../constans/localstorage';
import { findAndReturnChangedError } from '../helpers/utils';

toastr.options = {
  progressBar: true,
  preventDuplicates: true,
};

const axiosConfig = {
  baseURL: process.env.API_URL,
  useToast: true,
};

const STARTDATE_VALIDATION_ERROR = '"startDate" must be a number of milliseconds or valid date string';
const SET_DATES_ERROR = 'Please set Start Date and End Date';

const store = StorageService();
const restApi = axios.create(axiosConfig);

const refresh = async (token) => axios
  .create({
    ...axiosConfig,
    headers: { Authorization: `Bearer ${store.get(LOCALSTORAGE_KEYS.SESSION).accessToken}` },
  })
  .put('/sessions', { refreshToken: token })
  .then(({ data }) => data)
  .catch(() => navigate('/signin'));

const retryRequest = async (config) => {
  const { data } = await axios.request(config);
  return data;
};

const requestInterceptor = (request) => {
  const key = store.get(LOCALSTORAGE_KEYS.SESSION).accessToken;
  const newRequest = { ...request };
  newRequest.headers.Authorization = `Bearer ${key}`;

  return newRequest;
};

const requestErrorInterceptor = async (error) => error;

const responseInterceptor = ({ data }) => data;

const responseErrorInterceptor = async ({ response, config }) => {
  if (!response) {
    // toast('Network error');
    toastr('Network error');
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({
      status: HTTP_STATUS.INTERNAL_ERROR,
      code: HTTP_STATUS.INTERNAL_ERROR,
      message: 'Network error',
      data: {
        error: {
          message: 'Network error',
        },
      },
    });
  }

  const {
    status,
    data: { error },
  } = response;
  const { refreshToken } = store.get(LOCALSTORAGE_KEYS.SESSION);
  const errorData = {
    status,
    message: (error && error.message) || '',
    code: (error && error.code) || '',
  };

  // Refresh token in case of 401 (UNAUTHORIZED) response
  if (errorData.status === HTTP_STATUS.UNAUTHORIZED) {
    if (refreshToken) {
      try {
        const { data: newSession } = await refresh(refreshToken);
        const newConfig = { ...config };

        store.set(LOCALSTORAGE_KEYS.SESSION, newSession);
        newConfig.headers.Authorization = `Bearer ${newSession.accessToken}`;

        return retryRequest(newConfig);
      } catch (err) {
        return Promise.reject(errorData);
      }
    } else {
      return Promise.reject(errorData);
    }
  } else if (config.useToast) {
    toastr.error(findAndReturnChangedError(errorData.message));
  }
  return Promise.reject(errorData);
};

restApi.interceptors.request.use(requestInterceptor, requestErrorInterceptor);
restApi.interceptors.response.use(
  responseInterceptor,
  responseErrorInterceptor,
);

export const createInstance = (config = axiosConfig) => {
  const http = axios.create(config);

  http.interceptors.request.use(requestInterceptor, requestErrorInterceptor);
  http.interceptors.response.use(
    responseInterceptor,
    responseErrorInterceptor,
  );

  return http;
};

export default restApi;

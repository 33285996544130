import React from 'react';
import ReactDOM from 'react-dom';
import SEO from './src/components/Seo';
import StorageService from './src/services/storage.service';
import { LOCALSTORAGE_KEYS } from './src/constans/localstorage';
import http from './src/services/http.service';
import Roles from './src/constans/roles';

import 'antd/dist/antd.min.css';
import 'toastr/build/toastr.min.css';
import './src/styles/index.scss';
import './src/styles/app.scss';
import './src/styles/tables.scss';
import './src/styles/temp.scss';
import { navigate } from 'gatsby';

/**
 * `onClientEntry` works when user enters website and on each window Reload
 */
export function onClientEntry() {
  const store = StorageService();
  const isSignedIn = store.get(LOCALSTORAGE_KEYS.SESSION, {})?.accessToken;
  const isSupplier =
    store.get(LOCALSTORAGE_KEYS.USER, {})?.role === Roles.SUPPLIER;

  if (isSignedIn && isSupplier) {
    http
      .get('suppliers/my')
      .then(({ data }) => store.set(LOCALSTORAGE_KEYS.USER, data))
      .catch(() => {
        /* do nothing */
      });
  }
}

export function replaceHydrateFunction() {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback);
  };
}

export function wrapRootElement({ element }) {
  return (
    <>
      <SEO title="Koralgo" />
      {element}
    </>
  );
}

export function onRouteUpdate({ location }) {
  const { pathname } = location;
  const pagesToStoreSearch = [
    '/activities/search',
    '/activities/search/results',
    '/activities',
    '/itineraries/search',
    '/itineraries',
    '/properties/results',
    '/customers/bookings/create',
    '/search',
    '/results',
    '/nothing-found',
    '/results-shared',
    // in stage browser adds a slash to the end of the line
    '/properties/results/',
    '/activities/results/',
    '/results-shared/',
    '/activities/',
    '/nothing-found/',
    //
    '/',
  ];

  const pagesToStoreActivitySearch = [
    '/activities/search',
    '/activities/search/results',
    '/activities/results',
    '/activities',
    '/itineraries/search',
    '/itineraries',
    '/search',
    '/results',
    '/nothing-found',
    '/results-shared',
    // in stage browser adds a slash to the end of the line
    '/activities/results/',
    '/results-shared/',
    '/activities/',
    '/nothing-found/',
    //
    '/',
  ];

  const pagesToStoreItinerariesSearch = [
    '/activities/search',
    '/activities/search/results',
    '/itineraries/search',
    '/itineraries',
    '/activities',
    '/properties/results',
    '/activities/results',
    '/search',
    '/results',
    '/nothing-found',
    '/results-shared',
    // in stage browser adds a slash to the end of the line
    '/properties/results/',
    '/activities/results/',
    '/results-shared/',
    '/activities/',
    '/nothing-found/',
    //
    '/',
  ];

  if (!pagesToStoreSearch.includes(pathname)) {
    StorageService().remove(LOCALSTORAGE_KEYS.SEARCH);
  }

  if (!pagesToStoreActivitySearch.includes(pathname)) {
    StorageService().remove(LOCALSTORAGE_KEYS.ACTIVITY_SEARCH);
  }

  if (!pagesToStoreItinerariesSearch.includes(pathname)) {
    StorageService().remove(LOCALSTORAGE_KEYS.ITINERARIES_SEARCH);
  }

  const statusCode = location.state && location.state.status;

  if (statusCode === 403 || statusCode === 404) {
    navigate('/404');
  }
}

import { LOCALSTORAGE_KEYS } from '../constans/localstorage';

const version = 'v1';

const noop = () => ({ /* do nothing */ });

class Observable {
  constructor() {
    this.handlers = {};
  }

  set(key, val) {
    const { handlers } = this;
    (handlers[key] || []).forEach((handlerFunc) => handlerFunc(val));
  }

  subscribe(key, func) {
    let handlers = this.handlers[key];
    if (!handlers) {
      // eslint-disable-next-line no-multi-assign
      handlers = this.handlers[key] = [];
    }
    handlers.push(func);
    return () => this.unsubscribe(key, func);
  }

  unsubscribe(key, func) {
    this.handlers[key] = (this.handlers[key] || []).filter((handlerFunc) => handlerFunc !== func);
  }
}

const observable = new Observable();

const StorageService = () => {
  const isBrowser = typeof window !== 'undefined' && window.localStorage;

  if (isBrowser) {
    const keyGen = (key) => `koral:${key}__${version}`;

    const remove = (key) => localStorage.removeItem(keyGen(key));

    const clear = () => {
      const cookies = localStorage.getItem(keyGen(LOCALSTORAGE_KEYS.COOKIES));
      localStorage.clear();
      Object.keys(observable.handlers).forEach((key) => observable.set(key, null));

      localStorage.setItem(keyGen(LOCALSTORAGE_KEYS.COOKIES), cookies || false);
    };

    const set = (key, value) => {
      localStorage.setItem(keyGen(key), JSON.stringify(value));
      observable.set(key, value);
    };

    const onChange = (key, changeCallBack) => observable.subscribe(key, changeCallBack);

    const get = (key, defaultValue = {}) => {
      const res = localStorage.getItem(keyGen(key));

      try {
        return res ? JSON.parse(res) : defaultValue;
      } catch {
        return defaultValue;
      }
    };

    return {
      remove,
      clear,
      get,
      set,
      onChange,
    };
  }

  return {
    remove: noop,
    clear: noop,
    get: noop,
    set: noop,
  };
};

export default StorageService;

export const PASSWORD_INVALID_TIP = 'Password should contain between 8 and 50 characters. At least one letter, one digit and one capital letter.';
export const FULL_NAME_INVALID_TIP = 'Full name should contain between 2 and 80 characters';
export const PASSWORD_VALID = 'Valid password format';
export const CONFIRM_PASSWORD_DOESNT_MATCH = "Password doesn't match";
export const CONFIRM_PASSWORD_REQUIRED = 'Confirm Password is required';
export const PASSWORD_REQUIRED = 'Password is required';
export const CURRENT_PASSWORD_REQUIRED = 'Current password is required';
export const EMAIL_REQUIRED = 'Email is required';
export const EMAIL_INVALID = 'Email is invalid';
export const AT_LEAST_ONE_FIELD = 'Select at least one option';
export const MUST_BE_A_NUMBER = 'Must be a number';

export const PHOTO_TITLE_REQUIRED = 'Photo title is required';
export const PHOTO_TITLE_NO_SPACES = 'Must be from 1 to 500 characters and cannot contain spaces only';

// locations and transport
export const LOCATION_NAME_NO_SPACES = 'Must be from 1 to 50 characters and cannot contain spaces only';

// Create Offer
export const OFFER_NAME_REQUIRED = 'Offer Name is required';
export const OFFER_NAME_MATCHES = 'Must be from 1 to 50 characters and cannot contain spaces only';
export const OFFER_CODE_REQUIRED = 'Offer code is required';
export const OFFER_CODE_MATCHES = 'Offer code must contain 6 alphanumeric characters';
export const OFFER_ROOM_TYPE_REQUIRED = 'Room type is required';
export const OFFER_MAX_GUESTS_REQUIRED = 'Max number of guests is required';
export const OFFER_MAX_CAPACITY = 'Must be less or equal maximum capacity';
export const OFFER_START_DATE_REQUIRED = 'Start Date is required';
export const OFFER_END_DATE_REQUIRED = 'End Date is required';
export const OFFER_END_DATE_LATE = 'End Date must be late than :val';
export const OFFER_MIN_SAY_DURATION_REQUIRED = 'Minimum stay duration is required';
export const OFFER_MIN_STAY_DURATION_PERIOD = 'Must be from 1 to 31';
export const OFFER_INV_NUMBER_REQUIRED = 'Inventory number is required';
export const OFFER_INV_NUMBER_RANGE = 'Must be from 1 to 1000';
export const OFFER_NEW_FEATURE_NAME = 'Must be from 1 to 50 characters and cannot contain spaces only';
export const OFFER_NEW_FEATURE_NAME_REQUIRED = 'Feature Name is required';
export const OFFER_BASE_PRICE_REQUIRED = 'Base price is required';
export const OFFER_BASE_PRICE_INVALID = 'Base price is invalid';
export const ADDITIONAL_PRICE_REQUIRED = 'Additional price per guest is required';
export const ADDITIONAL_PRICE_INVALID = 'Additional price per guest is invalid';
export const TAX_NAME_REQUIRED = 'Name tax is required';
export const TAX_NAME_TOO_LONG = 'Name tax shoulb be up to 25 characters long';
export const TAX_VALUE_REQUIRED = 'Tax value is required';
export const TAX_VALUE_INVALID = 'Tax value is invalid';
export const TAX_DESCRIPTION_INVALID = 'Must be from 1 to 100 characters and cannot contain spaces only';
export const CANCELLATION_VALUE_INVALID = 'Must be from 0 to 100, can not contain spaces or characters';
export const CANCELLATION_AMOUNT_MIN = 'Can not be less then 0';
export const CANCELLATION_AMOUNT_TYPE = 'Must be a positive number';
export const MAX_NUMBER_OF_GUESTS_MUST_BE_A_NUMBER = 'Max Number Of Guests must be a number';
export const MAX_VALUE_EXCEED = 'Max value is exceeded';
export const CANT_PERFORM_BOOKING_CREATION = 'This account can\'t be used for the booking creation';
export const PAYMENT_DEAD_LINE_INVALID = 'Max days before check-in date is 55';
export const PAYMENT_DEAD_LINE_REQUIRED = 'Days before check-in is required';
export const ONE_OF_EDIT_POLICY_CHANGE_REQUIRED = 'One of change type is required';

export const HOW_FIND_REQUIRED = 'This field required';
export const CHANNEL_MANAGER_REQUIRED = 'This field required';
export const WEBSITE_LINK_REQUIRED = 'Website link is required';
export const WEBSITE_LINK_INVALID = 'Website link invalid';
export const ADDRESS_INVALID = 'Address is required';
export const NUMBER_OF_ROOMS_REQUIRED = 'Number of rooms is required';
// export const NUMBER_OF_ROOMS_REQUIRED = "Number of rooms is invalid";
export const CURRENCY_REQUIRED = 'Currency is required';
export const EXPRESS_PROPERTY_PRICE_REQUIRED = 'Price is required';
export const ROOM_SERVICE_DESCRIPTION = 'Must be from 1 to 500 characters and cannot contain spaces only';
export const PROPERTY_EDIT_DESCR = 'Must be from 1 to 500 characters and cannot contain spaces only';
export const PROPERTY_EDIT_BRAND = 'Must be from 1 to 50 characters and cannot contain spaces only';
export const PROPERTY_EDIT_GENERAL_INTRO_REQUIRED = 'General intro and description is required';
export const COMMENT_REQUIRED = 'Comment is required';
export const TITLE_REQUIRED = 'Title is required';
export const TYPE_REQUIRED = 'Type of transport is required';
export const DESCR_REQUIRED = 'Description is required';
export const NAME_REQUIRED = 'Name is required';
export const DISTANCE_REQUIRED = 'Distance is required';
export const SPACES_ONLY = 'Cannot contain spaces only';
export const FACILITIY_TITLE_REQUIRED = 'Facility title is required';
export const FACILITY_PRICE_REQUIRED = 'Price is required';
export const ROOM_TYPE_REQUIRED = 'Room type is required';
export const SURFACE_REQUIRED = 'Surface is required';
export const SURFACE_INVALID = 'Surface is invalid';
export const ROOM_TITLE_INVALID = 'Must be from 1 to 50 characters and cannot contain spaces only';
export const ROOM_DESCR_INVALID = 'Must be from 1 to 100 characters and cannot contain spaces only';
export const DES_CAP_REQUIRED = 'Designed capacity is required';
export const VALUES_MUST_BE_POSITIVE = 'Value must be positive';
export const ADULTS_REQUIRED = 'Adults is required';
export const CAPACITY_MAX = 'Must be from 1 to 99';
export const ROOMS_PHOTOS_CREATE_MAX = 'Photos limit for first upload is 10';
export const ROOMS_PHOTOS_PER_UPLOAD_MAX = 'Max photos amount per upload is 10';
export const ROOMS_PHOTOS_MAX = 'Max photos amount is 50';
export const ROOMS_PHOTOS_MIN = 'At least one photo is required for upload';
// export const OPT_CAP_REQUIRED = 'Optimized capacity is required';
// export const MAX_CAP_REQUIRED = 'Maximum capacity is required';
export const ROOMS_CUSTOM_AMENITY_REQUIRED = 'Amenity title is required';
export const ROOMS_CUSTOM_AMENITY_INVALID = 'Must be from 1 to 50 characters and cannot contain spaces only';
export const ROOMS_BED_TYPE_TITLE_REQUIRED = 'Bed type is required';
export const ROOMS_BED_TYPE_WIDTH_REQUIRED = 'Bed width is required';
export const ROOMS_BED_TYPE_WIDTH_MAX = 'Bed width is invalid';
export const DISTANCE_INVALID = 'Distance is invalid';
export const MAX_CAP_SUM = 'Maximum capacity should be more than or equal to Designed capacity';
export const OPT_CAP_LESS_SUM = 'Optimized capacity should be less or equal to Maximum capacity';
export const OPT_CAP_MAX_SUM = 'Optimized capacity should be more than or equal to Designed capacity';
export const DES_CAP_SUM = 'Optimized capacity should be more than or equal to Designed capacity';
// Complete profile
export const BIRTH_DATE_MAX = 'Incorrect birth date';
export const BIRTH_DATE_ADULT_MIN = 'Registered User must be at least 14 years old';
export const USER_TITLE_REQUIRED = 'Title is required';
export const BIRTH_DATE_REQUIRED = 'Birth date is required';
export const NICKNAME_REQUIRED = 'Nickname is required';
export const NICKNAME_INVALID = 'Must be from 1 to 50 characters and cannot contain spaces only';
export const USER_NAME_INVALID = 'Must be from 1 to 50 characters and cannot contain spaces only';
export const USER_FIRST_NAME_REQUIRED = 'First name is required';
export const USER_LAST_NAME_REQUIRED = 'Last name is required';
export const USER_PASSPORT_INVALID = 'Must be from 1 to 20 characters and cannot contain spaces only';
export const PHONE_REQUIRED = 'Phone is required';
export const PHONE_INVALID = 'Phone number is invalid';
export const PROPERTY_LOCATION_REQUIRED = 'Location is required';
export const PROPERTY_LOCATION_INVALID = 'Location is invalid';
export const ADDRESS_REQUIRED = 'Address is required';
export const ADDRESS_IS_INVALID = 'Address cannot contain spaces only';
export const NATIONALITY_REQUIRED = 'Nationality is required';

//  Contact
export const TOPIC_REQUIRED = 'Topic is required';
export const MESSAGE_REQUIRED = 'Message is required';
export const MESSAGE_INVALID = 'Must be from 1 to 300 characters and cannot contain spaces only';

//  Bookings
export const ARRIVAL_TIME_REQUIRED = 'Arrival time is required';
export const CHECK_IN_DATE_REQUIRED = 'Check in date is required';
export const CHECK_OUT_DATE_REQUIRED = 'Check out date is required';
export const COMMENT_INVALID = 'Must be from 1 to 255 characters and cannot contain spaces only';
export const KID_AGE_REQUIRED = 'Please provide children age';
export const BOOKING_CHECK_OUT_DATE_LATE = 'Check out date must be late than :val';

//  Files
export const IMG_UPLOAD_FAILED = 'Failed image uploading';

// Activity search
export const ADULTS_AGE = 'Must be from 18 to 99';

// Destination
export const DESTINATION_REQUIRED = 'Please select a valid destination';
export const DATE_REQUIRED = 'Please provide dates';

export const LOCATION_ERRORS = [
  { SERVER_ERROR: '"regionId" is required', READABLE_ERROR: DESTINATION_REQUIRED },
  { SERVER_ERROR: '"regionId" must be a number', READABLE_ERROR: DESTINATION_REQUIRED },
];
